import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { checkUserToken, getUserRole } from '@/api/config'
import auth from './modules/auth'
import landingPage from './modules/landingPage'
import accessAdmin from './modules/access_admin'
import contentAdmin from './modules/content_admin'
import dart from './modules/dart'
import commissioner from './modules/commissioner'
import presidingCommissioner from './modules/presiding_commissioner'
import deputyPresidingCommissioner from './modules/deputy_presiding_commissioner'
import secretaryCommission from './modules/secretary_of_the_commission'
import hotel from './modules/hotel'
import tourOperator from './modules/tour_operator'
import certificationAgency from './modules/certification_agency'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'error',
    component: () => import('@/components/error/ErrorPage.vue'),
  },
  ...landingPage,
  ...auth,
  ...accessAdmin,
  ...contentAdmin,
  ...dart,
  ...commissioner,
  ...presidingCommissioner,
  ...deputyPresidingCommissioner,
  ...secretaryCommission,
  ...hotel,
  ...tourOperator,
  ...certificationAgency,

  {
    path: '/view-vote-result-categorisation/:id',
    redirect: (to) => {
      if (!!checkUserToken()) {
        return {
          name: store.getters.getVoteResultCategorisationPath,
          params: { id: to.params.id, activity: 'readonly' },
        }
      } else {
        return {
          name: 'auth-password',
          params: {
            redirect: `/view-vote-result-categorisation/${to.params.id}`,
          },
        }
      }
    },
  },
  {
    path: '/view-vote-result-categorisation-history/:id',
    redirect: (to) => {
      if (!!checkUserToken()) {
        return {
          name: store.getters.getVoteResultCategorisationHistoryPath,
          params: { id: to.params.id, activity: 'readonly' },
        }
      } else {
        return {
          name: 'auth-password',
          params: {
            redirect: `/view-vote-result-categorisation/${to.params.id}`,
          },
        }
      }
    },
  },
  {
    path: '/view-vote-start-categorisation/:id',
    redirect: (to) => {
      if (!!checkUserToken()) {
        return {
          name: store.getters.getVoteStartCategorisationPath,
          params: { id: to.params.id, activity: 'readonly' },
        }
      } else {
        return {
          name: 'auth-password',
          params: {
            redirect: `/view-vote-start-categorisation/${to.params.id}`,
          },
        }
      }
    },
  },
  {
    path: '/view-categorisation-by-commission/:id',
    redirect: (to) => {
      if (!!checkUserToken()) {

        const role = getUserRole()
        let route = {}

        switch (role) {
          case 'presiding_commissioner':
            route = {
              name: 'presiding-commissioner_vote-review',
              params: { id: to.params.id },
            }
            break;

          case 'deputy_presiding_commissioner':
            route = {
              name: 'deputy-presiding-commissioner_vote-review',
              params: { id: to.params.id },
            }
            break;
        
          default:
            route = {
              name: 'secretary-commission_categorization-review',
              params: { id: to.params.id, activity: 'readonly' },
            }
            break;
        }

        return route;
        
      } else {
        return {
          name: 'auth-password',
          params: {
            redirect: `/view-categorisation-by-commission/${to.params.id}`,
          },
        }
      }
    },
  },
  {
    path: '/view-categorisation-by-dart/:id',
    redirect: (to) => {
      if (!!checkUserToken()) {
        return {
          name: 'dart_categorization_review',
          params: { id: to.params.id, activity: 'readonly' },
        }
      } else {
        return {
          name: 'auth-password',
          params: {
            redirect: `/view-categorisation-by-dart/${to.params.id}`,
          },
        }
      }
    },
  },
  {
    path: '/view-report-by-commission/:id',
    redirect: (to) => {
      if (!!checkUserToken()) {
        return {
          name: store.getters.getCommissionReportPath,
          params: { id: to.params.id, activity: 'readonly' },
        }
      } else {
        return {
          name: 'auth-password',
          params: { redirect: `/view-report-by-commission/${to.params.id}` },
        }
      }
    },
  },
  {
    path: '/view-report-by-dart/:id',
    redirect: (to) => {
      if (!!checkUserToken()) {
        return {
          name: 'dart_tour-operator-report_review',
          params: { id: to.params.id, activity: 'readonly' },
        }
      } else {
        return {
          name: 'auth-password',
          params: { redirect: `/view-report-by-dart/${to.params.id}` },
        }
      }
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      }
    }
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {

  // to.name !== 'auth-password' &&
  // to.name !== 'landing-page' &&
  // to.name !== 'search-results' &&
  // to.name !== 'contacts' &&
  // to.name !== 'auth-key' &&
  // to.name !== 'reset-password' &&
  // to.name !== 'create-password' &&
  // to.name !== 'news-all' &&
  // to.name !== 'error' &&
  // to.name !== 'news-details' &&
  // to.name !== 'statistic' &&
  if (
    to.meta.name !== 'home' &&
    to.meta.name !== 'auth' &&
    to.name !== 'error' &&
    !checkUserToken()
  ) {
    next({ name: 'auth-password' })
    store.commit('LOGOUT_USER')
  } else {
    store.commit('RESET_TABLE_SORT_DATA')
    store.commit('SET_LAST_PAGE', null)
    next()
  }
})

export default router
