import { Axios, API_VERSION, config } from '@/api/config';

export const getTourOperatorReportsTable = (data) => {
  const url = `${API_VERSION}/tour-operator/report/table`;
  return Axios.get(url, { params: data });
}

export const getIsReportsTableExistsDraft = () => {
  const url = `${API_VERSION}/tour-operator/report/table/exists-draft`;
  return Axios.get(url);
}

export const getTourOperatorReportStructure = (data) => {
  const {uuid, lang} = data;
  let reportData = !!uuid ? `?report_uuid=${uuid}` : '';

  const url = `${API_VERSION}/tour-operator/report/form/structure${reportData}`;
  config.headers['Accept-Language'] = lang;

  return Axios.get(url, config);
}

export const createReportsDraft = (data) => {
  const url = `${API_VERSION}/tour-operator/report/form/create-draft`;
  return Axios.post(url, data);
}

export const editReportsDraft = (data) => {
  const url = `${API_VERSION}/tour-operator/report/form/edit`;
  return Axios.post(url, data);
}

export const іsignTourOperatorReport = (data) => {
  const url = `${API_VERSION}/tour-operator/report/form/sign`;
  return Axios.post(url, data);
}

export const getReportsData = (data) => {
  const url = `${API_VERSION}/tour-operator/report/form`;
  return Axios.get(url, { params: data });
}

export const deleteReportsDraft = (data) => {
  const url = `${API_VERSION}/tour-operator/report/form`;
  return Axios.delete(url, { params: data });
}

export const getReportsComments = (data) => {
  const url = `${API_VERSION}/tour-operator/report/form/comments`;
  return Axios.get(url, { params: data });
}

export const existingReportInCurrentPeriod = () => {
  const url = `${API_VERSION}/tour-operator/report/table/created-report-in-current-period`;
  return Axios.get(url);
};
