export const menu = [
  {
    name: 'reports',
    path_name: 'tour-operator_reports-list',
    rooth_name: 'report'
  },
  {
    name: 'profile',
    path_name: 'tour-operator_profile',
    rooth_name: 'profile'
  },
  {
    name: 'notifications',
    path_name: 'tour-operator_notifications',
    rooth_name: 'notifications'
  },
];
