export const menu = [
  {
    name: 'hotel_requests',
    path_name: 'dart_hotel-categorizations',
    rooth_name: 'categorization'
  },
  {
    name: 'tour_operator_reports',
    path_name: 'dart_tour-operator-reports',
    rooth_name: 'report'
  },
  {
    name: 'vote_history',
    path_name: 'dart_vote-history',
    rooth_name: 'vote'
  },
  {
    name: 'profile',
    path_name: 'dart_profile',
    rooth_name: 'profile'
  },
  {
    name: 'notifications',
    path_name: 'dart_notifications',
    rooth_name: 'notifications'
  },
];
