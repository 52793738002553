export default [
  {
    path: '/certification-agency',
    name: 'certification_agency',
    component: () => import('@/components/certification_agency/Main.vue'),
    redirect: { name: 'certificate_agency_hotel_categorizations-list' },

    children: [
      {
        path: 'categorization',
        name: 'certificate_agency_hotel_categorizations-list',
        component: () => import('@/components/certification_agency/RequestsList.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'profile',
        name: 'certification_agency_profile',
        component: () => import('@/components/certification_agency/Profile.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'notification',
        name: 'certificate_agency_notifications',
        component: () => import('@/components/general/Notifications.vue'),
        meta: {
          isMenu: true,
        },
      },

      // {
      //   path: 'categorization',
      //   name: 'hotel_categorization',
      //   component: () => import('@/components/hotel/Categorization.vue'),
      //   meta: {
      //     isMenu: false,
      //     name: 'categorization_request',
      //   },
      // },
      {
        path: 'categorization/create',
        name: 'certification_agency_hotel_categorization-create',
        component: () => import('@/components/certification_agency/Categorization.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'categorization/:id',
        name: 'certification_agency_hotel_categorization-edit',
        component: () => import('@/components/certification_agency/Categorization.vue'),
        meta: {
          isMenu: true,
        },
      },

      // ======== for deep pages
      // {
      //   path: 'create-news',
      //   name: 'content_administrator-create-news',
      //   component: () => import('@/components/content_admin/NewsPage.vue'),
      //   meta: {
      //     isMenu: false,
      //     name: 'additing_news',
      //   },
      // },
    ],
  },
];
