import router from '@/router';
import {
  login,
  logout,
  resetPass,
  confirmResetPass,
  loginWithEuKey,
} from '@/api/services/auth.js';
import { setToLS, getFromLS, isLSHasItem } from '@/library/helpers';
import VueI18n from '@/i18n';
import { stat } from 'fs';

export default {
  state: {
    currentRole: isLSHasItem('currentRole') ? getFromLS('currentRole') : '',
    currentUserUuid: isLSHasItem('currentUserUuid')
      ? getFromLS('currentUserUuid')
      : null,
    token: isLSHasItem('token') ? getFromLS('token') : null,
    currentUser: isLSHasItem('currentUser') ? getFromLS('currentUser') : null,
    responseMessage: '',
    isError: false,
  },

  getters: {
    getCurrentRole: (state) => state.currentRole,
    getCurrentUser: (state) => state.currentUser,
    getResponseMessage: (state) => state.responseMessage,
    getIsError: (state) => state.isError,
  },

  mutations: {
    SET_USER(state, data) {
      const { token, user } = data;

      let obj = {};

      for (let key in user) {
        if (key === 'role') {
          obj = { ...obj, ...user[key] };
        } else {
          obj[key] = user[key];
        }
      }

      state.currentRole = obj.type;
      state.token = token;
      state.currentUserUuid = user.uuid;
      state.currentUser = obj;

      setToLS('currentUserUuid', user.uuid);
      setToLS('currentUser', obj);
      setToLS('currentRole', obj.type);
      setToLS('token', token);
    },

    LOGOUT_USER(state) {
      localStorage.clear();
      state.token = null;
      state.currentRole = '';
      state.currentUser = null;
    },
    GET_RESPONSE_MESSAGE(state, payload) {
      state.responseMessage = payload;
    },
    GET_IS_ERROR(state, payload) {
      state.isError = payload;
    },
  },

  actions: {
    async authorisation({ commit, dispatch }, data) {
      let res;
      try {
        res = await login(data);
        const statusCode = res ? res.status : null;

        if (statusCode === 200) {
          dispatch('setAuthUser', {
            data: res.data.data,
            redirect: res.data.data.redirect,
          });
        }
      } catch (e) {
        commit('SHOW_NOTIFICATION', true);
        // commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_RESPONSE_MESSAGE', VueI18n.t('notification.auth_default_error'));
        commit('GET_IS_ERROR', true);
        console.log('>>> authorisation error', e.response.data.message);
        return {
          error: true,
        };
      } finally {
        // commit('SET_LOADER', false);
      }
    },

    async authorisationWithEuKey({ commit, dispatch }, data) {
      commit('SET_RQUEST_LOADED', false);
      let res;
      try {
        res = await loginWithEuKey(data);
        const statusCode = res ? res.status : null;
        if (statusCode === 200) {
          commit('SET_RQUEST_LOADED', true);
          // dispatch('setAuthUser', { ...res.data.data });
          dispatch('setAuthUser', {
            data: res.data.data,
            redirect: res.data.data.redirect,
          });
        }
      } catch (e) {
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
        console.log(
          '>>> authorisationWithEuKey error',
          e.response.data.message
        );
      }
    },

    async userLogout({ commit }) {
      let res;
      try {
        res = await logout();

        if (res.status === 200) {
          commit('LOGOUT_USER');
          commit('SET_AUTH_USER_DATA', null); //================ ??
          commit('RESET_CURRENT_FORM_TYPE');

          await router.push({ name: 'auth-password' });

          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.unauth_success')
          );
          commit('GET_IS_ERROR', false);
        }
      } catch (e) {
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async resetPassword({ commit }, data) {
      let res;
      try {
        res = await resetPass(data);
        if (res.status === 200) {
          commit('modal/SET_MODAL', { state: true, name: 'reset-password' });
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.pass_is_reset')
          );
        }
      } catch (e) {
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async createNewPassword({ commit }, data) {
      let res;
      try {
        res = await confirmResetPass(data);
        if (res.status === 200) {
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.pass_is_created')
          );
        }
      } catch (error) {
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    setAuthUser({ commit }, payload) {
      const { access_token: token, user: user } = payload.data;
      commit('SET_USER_EU_KEY', user.is_has_ecp);
      commit('SET_USER', { token, user }); //============= ??
      commit('SHOW_NOTIFICATION', true);
      commit('GET_RESPONSE_MESSAGE', VueI18n.t('notification.auth_success'));
      commit('GET_IS_ERROR', false);

      if (!!payload.redirect) {
        router.push({ path: payload.redirect });
      } else {
        router.push({ name: user.role.type });
      }
    },
  },
};
