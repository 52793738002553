import {
  getTableOfCategorizationsHotel,
  getIsHotelRequestsTableExistsDraft,
  existingCategorizationInCurrentPeriod,
  addCategorizationRequestDraft,
  getCategorizationFormStructure,
  getCategorizationHotel,
  deleteCategorizationRequest,
  editCategorizationRequest,
  createFullCategorizationRequest,
  getCommentsHotel,
} from '@/api/services/certification_agency.js';
import router from '@/router';
import VueI18n from '@/i18n';

export default {
  state: {
    tableOfCategorizationsHotelsCA: [],
    categorizationFormStructureCA: [],
    categorizationFormStateCA: [],
    hotelRequestTableExistsDraftCA: false,
    existingCategorizationCA: false,
    categorizationDraftSavedCA: false,
    сommentsHotelCA: [],
  },

  getters: {
    getTableOfCategorizationsHotelCA: (state) => state.tableOfCategorizationsHotelsCA,
    getCategorizationHotelCA: (state) => state.categorizationFormStateCA,
    getCommentsHotelCA: (state) => state.сommentsHotelCA,
  },

  mutations: {
    SET_CATEGORIZATION_TABLE_CA(state, data) {
      state.tableOfCategorizationsHotelsCA = data;
    },

    SET_HOTEL_REQUEST_TABLE_EXISTS_DRAFT_CA(state, value) {
      state.hotelRequestTableExistsDraftCA = value;
    },

    SET_EXISTING_CATEGORISATION_CA(state, value) {
      state.existingCategorizationCA = value;
    },

    SET_CATEGORIZATION_FORM_STATE_CA(state, data) {
      state.categorizationFormStateCA = data;
    },

    SET_CATEGORISATION_DRAFT_SAVEDCA_CA(state, value) {
      state.categorizationDraftSavedCA = value;
    },

    SET_COMMENTS_HOTEL_CA(state, data) {
      state.сommentsHotelCA = data;
    },
  },

  actions: {
    clearStore({ commit }) {
      commit('CLEAR_STORE');
    },

    async getTableOfCategorizationsHotelCA({ commit, getters, dispatch }) {
      try {
        let res;

        res = await getTableOfCategorizationsHotel(getters.getTableSortData);
        if (res.status === 200) {
          dispatch('getIsHotelRequestsTableExistsDraftCA');
          dispatch('checkExistingCategorizationInCurrentPeriodCA');
          commit('SET_CATEGORIZATION_TABLE_CA', res.data.data);
          commit('SET_CURRENT_PAGE', res.data.data.table.current_page);
          commit('SET_LAST_PAGE', res.data.data.table.last_page);
        }
      } catch (e) {
        console.log(
          '>>> error getTableOfCategorizationsHotelCA',
          e.response.data.error
        );
      }
    },

    async getIsHotelRequestsTableExistsDraftCA({ commit }) {
      let res;
      try {
        res = await getIsHotelRequestsTableExistsDraft();
        if (res.status === 200) {
          commit(
            'SET_HOTEL_REQUEST_TABLE_EXISTS_DRAFT',
            res.data.data.exists_draft
          );
        }
      } catch (e) {
        console.log('>>>> getIsHotelRequestsTableExistsDraftCA error', e);
      }
    },

    async checkExistingCategorizationInCurrentPeriodCA({ commit }) {
      let res;
      try {
        res = await existingCategorizationInCurrentPeriod();
        if (res.status === 200) {
          commit(
            'SET_EXISTING_CATEGORISATION_CA',
            res.data.data.is_created_application_in_current_period
          );
        }
      } catch (e) {
        console.log('>>>> checkExistingCategorizationInCurrentPeriodCA error', e);
      }
    },

    async addCategorizationRequestDraftCA({ commit }, data) {
      let res;
      try {
        res = await addCategorizationRequestDraft(data);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', VueI18n.t('notification.data_is_saved'));
        router.push({ name: 'certificate_agency_hotel_categorizations-list' });
      } catch (e) {
        commit('SHOW_NOTIFICATION', true);

        for(let k in e.response.data.errors) {
          commit('GET_RESPONSE_MESSAGE', e.response.data.errors[k][0]);
        }
        commit('GET_IS_ERROR', true);
      }
    },

    async getCategorizationHotelCA({ commit }, data) {
      let res;
      try {
        res = await getCategorizationHotel(data);
        commit(
          'SET_CATEGORIZATION_FORM_STATE_CA',
          res.data.data.categorization_application
        );
      } catch (e) {
        console.log('>>> getCategorizationHotelCA error', e);
      }
    },

    async deleteCategorizationCA({ commit, dispatch }, uuid) {
      let res;
      let data = { categorization_application_uuid: uuid };
      try {
        res = await deleteCategorizationRequest(data);
        if (res.status === 200) {
          dispatch('getTableOfCategorizationsHotelCA');
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.draft_deleted')
          );
        }
      } catch (e) {
        console.log('>>>> deleteCategorizationCA error', e);
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response);
        commit('GET_IS_ERROR', true);
      }
    },

    async editCategorizationRequestCA({ commit }, data) {
      let res;
      try {
        res = await editCategorizationRequest(data);
        if (res.status === 200) {
          router.push({ name: 'certificate_agency_hotel_categorizations-list' });
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.data_is_saved')
          );
        }
      } catch (e) {
        commit('SHOW_NOTIFICATION', true);
        commit('GET_RESPONSE_MESSAGE', e.response.data.message);
        commit('GET_IS_ERROR', true);
      }
    },

    async createFullCategorizationRequestCA({ commit }, data) {
      let res;
      try {
        res = await createFullCategorizationRequest(data);
        // res = await addCategorizationRequestDraft(data);
        if (res.status === 200) {
          router.push({ name: 'certificate_agency_hotel_categorizations-list' });
          // commit('SET_CATEGORISATION_DRAFT_SAVED_CA', true);
          commit('GET_IS_ERROR', false);
          commit('SHOW_NOTIFICATION', true);
          commit(
            'GET_RESPONSE_MESSAGE',
            VueI18n.t('notification.data_is_saved')
          );
          commit(
            'SET_DATA_TO_SIGN',
            res.data.data.categorization_application.uuid
          );
          // commit('modal/SET_MODAL', {
          //   state: true,
          //   name: 'add-key',
          //   size: 'l',
          // });
        }
      } catch (e) {
        commit('SHOW_NOTIFICATION', true);
        // commit('GET_RESPONSE_MESSAGE', e.response.data?.errors.application);
        for(let k in e.response.data.errors) {
          commit('GET_RESPONSE_MESSAGE', e.response.data.errors[k][0]);
        }
        commit('GET_IS_ERROR', true);
        // router.push({ name: 'certificate_agency_hotel_categorizations-list' });
        console.log(
          '>>> createFullCategorizationRequestCA error',
          e.response.data?.errors.application
        );
      }
    },

    async signEditedCategorizationRequestCA({ commit }, data) {
      let res;
      try {
        res = await editCategorizationRequest(data);
        if (res.status === 200) {
          commit('SET_CATEGORISATION_DRAFT_SAVED', true);
          // commit('modal/SET_MODAL', {
          //   state: true,
          //   name: 'add-key',
          //   size: 'l',
          // });
        }
      } catch (e) {
        console.log('>>>> signEditedCategorizationRequestCA', e);
      }
    },

    async getCommentsHotelCA({ commit }, uuid) {
      let res;
      try {
        res = await getCommentsHotel(uuid);
        commit('SET_COMMENTS_HOTEL_CA', res.data.data.criterias);
      } catch (e) {
        console.log('>>> getCommentsHotelCA error', e);
      }
    },

  }
}
