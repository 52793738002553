export default [
  {
    filing_status: 'submitted',
    review_status: 'approved',
    is_voted: false,
    buttons: ['print', 'downloadCertificate', 'downloadPDF'],
    comments_visible: true,
    can_comment: false,
    edit: 'not_allowed',
    schedule: 'none',
  },
  {
    filing_status: 'submitted',
    review_status: 'declined',
    is_voted: false,
    buttons: ['print'],
    comments_visible: true,
    can_comment: false,
    edit: 'not_allowed',
    schedule: 'none',
  },
  {
    filing_status: 'submitted',
    review_status: 'waiting_for_voting_date_signed',
    is_sign: true,
    buttons: ['signAndSend', 'changeDate', 'print'], // check
    comments_visible: true,
    can_comment: true,
    edit: 'not_allowed',
    schedule: 'edit',
  },
  {
    filing_status: 'submitted',
    review_status: 'waiting_for_voting',
    is_sign: true,
    buttons: {
      buttonsBeforeVoting: ['vote', 'print'],
      buttonsDuringVoting: ['preliminaryResults', 'vote', 'print'],
      buttonsAfterVoting: ['preliminaryResults', 'print'],
    },
    comments_visible: true,
    can_comment: false,
    edit: 'not_allowed',
    schedule: 'view',
  },
  // {
  //   filing_status: 'submitted',
  //   review_status: 'waiting_for_voting',
  //   is_voted: false,
  //   voting_active: true,
  //   buttons: ['preliminaryResults', 'vote', 'print'],
  //   comments_visible: true,
  //   can_comment: false,
  //   edit: 'not_allowed',
  //   schedule: 'view',
  // },
  // {
  //   filing_status: 'submitted',
  //   review_status: 'waiting_for_voting',
  //   is_voted: true,
  //   voting_active: true,
  //   buttons: ['preliminaryResults', 'voteDisabled', 'print'],
  //   comments_visible: true,
  //   can_comment: false,
  //   edit: 'not_allowed',
  //   schedule: 'view',
  // },
];
