export default [
  {
    path: '/tour-operator',
    name: 'tour_operator',
    component: () => import('@/components/tour_operator/Main.vue'),
    redirect: { name: 'tour-operator_reports-list' },

    children: [
      {
        path: 'requests-list',
        name: 'tour-operator_reports-list',
        component: () => import('@/components/tour_operator/ReportsList.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'report/create',
        name: 'tour-operator_report-create',
        component: () =>
          import('@/components/tour_operator/TourOperatorReport.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'report/:id',
        name: 'tour-operator_report-edit',
        component: () =>
          import('@/components/tour_operator/TourOperatorReport.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'profile',
        name: 'tour-operator_profile',
        component: () => import('@/components/tour_operator/Profile.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'notification',
        name: 'tour-operator_notifications',
        component: () => import('@/components/general/Notifications.vue'),
        meta: {
          isMenu: true,
        },
      },
    ],

  },
];
