export default [
  {
    path: '/dart',
    name: 'dart',
    component: () => import('@/components/dart/Main.vue'),
    redirect: { name: 'dart_hotel-categorizations' },

    children: [
      {
        path: 'hotel-requests',
        name: 'dart_hotel-categorizations',
        component: () => import('@/components/dart/HotelRequests.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'categorization/:id',
        name: 'dart_categorization_review',
        component: () => import('@/components/dart/Categorization.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'tour-operator-reports',
        name: 'dart_tour-operator-reports',
        component: () =>
          import('@/components/dart/TourOperatorReportsList.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'tour-operator-reports/:id',
        name: 'dart_tour-operator-report_review',
        component: () => import('@/components/dart/TourOperatorReport.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'vote-history',
        name: 'dart_vote-history',
        component: () => import('@/components/dart/VoteHistory.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'vote-history/:id',
        name: 'dart_vote-history_review',
        component: () => import('@/components/dart/Categorization.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'vote-history/:id',
        name: 'dart_history-review',
        component: () =>
          import('@/components/dart/Categorization.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'profile',
        name: 'dart_profile',
        component: () => import('@/components/dart/Profile.vue'),
        meta: {
          isMenu: true,
        },
      },
      {
        path: 'notifications',
        name: 'dart_notifications',
        component: () => import('@/components/dart/Notifications.vue'),
        meta: {
          isMenu: true,
        },
      }
    ],
  },
];
